const Data = [
    {
      id: 1,
      category: "education",
      icon: "icon-graduation",
      year: "2023 - present",
      title: "Bachelor's Degree",
      desc: "System Analysis - UNINASSAU",
    },
    {
      id: 2,
      category: "education",
      icon: "icon-graduation",
      year: "2022 - 2023",
      title: "Professional Certificate",
      desc: "Meta Front-End Developer Specialization",
    },
    {
      id: 3,
      category: "education",
      icon: "icon-graduation",
      year: "2022 - 2023",
      title: "Professional Certificate",
      desc: "FrontEnd Web Development - DevQuest",
    },
    {
      id: 4,
      category: "experience",
      icon: "icon-briefcase",
      year: "Very soon in 2023",
      title: "FrontEnd Web Developer",
      desc: "A good developer who creates technological solutions with efficiency, creativity and innovation. I have curiosity, logical reasoning, problem solving, initiative and teamwork. Let's go with me? Just hire me. ✌",
    },
    {
      id: 5,
      category: "experience",
      icon: "icon-briefcase",
      year: "2021 - Present",
      title: "Customer Service Supervisor",
      desc: "Management and monitoring of employees of Claro Brasil, with a focus in operational indicators. Developed several reports and automations (used throughout Brazil) for the follow-up of these KPAs. I used a lot of VBA, HTML, CSS and a little of JS.",
    },
    {
      id: 6,
      category: "experience",
      icon: "icon-briefcase",
      year: "2018 - 2021",
      title: "Customer Service Expert",
      desc: "Claro Brazil's specialized customer retention team",
    },
  ];

  export default Data